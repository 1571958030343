import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/form-components/Select'))

const SelectDay = ({ isSubmitting, isSubmitted }) => {
    const days = Array.from(Array(32).keys()).slice(1)

    return (
        <Select
            name={'dobDay'}
            firstItem={'Day'}
            data={days}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

SelectDay.propTypes = {
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool
}

SelectDay.defaultProps = {
    isSubmitting: false,
    isSubmitted: false
}

export default SelectDay
